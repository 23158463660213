
interface IClaims {
  successMessage: string;
  errorMessage: string;
  loading: boolean;
  tableColumns: ITableWrapperColumns;
  selectedClaims: any[];
  customBulkMenuActions: { title: string; disabled: boolean }[];
  filters: any[];
  messages: any[];
  showClaimsChart: string;
  queryOverride: any;
  claimId: string;
  loadingText: string;
  queryForChart: any;
  claimType: string;
  claimsNotFoundImage: string;
}
import {
  claimsMapActions,
  claimsMapGetters,
  claimsMapState
} from "@/store/modules/claims";
import Help from "@/components/Help/Inquiries.vue";
import Vue from "vue";
import { ITableWrapperColumns } from "@/types";
import { tableColumnsListClaims } from "./ClaimsTable";
import ClaimStatusLookup from "../ClaimsLookup/ClaimStatusLookup.vue";
import Exporter from "@/components/Exporter/Exporter.vue";
import LossForm from "@/components/lossForm/LossForm.vue";
import { downloadClaimsDocument } from "@/helpers/downloadLossForm";
import {
  deleteClaimsDocument,
  restoreClaimsDocument
} from "@/helpers/deleteLossForm";
import {
  claimsInquiries,
  customBulkMenuOptions
} from "@/helpers/selectOptions";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { omit } from "lodash";
import {
  AtlasClaimRepresentativeOptions,
  FieldAdjusterOptions
} from "@/helpers/constants";

import Charts from "@/components/Charts/Charts.vue";
import { getChangeRequestTableRowActions } from "@/helpers/getChangeRequestTableRowActions";
import { excludedCompanies } from "@/helpers/companies";
import {
  companiesMapActions,
  companiesMapGetters
} from "@/store/modules/companies";
import { ICompany } from "@/store/modules/companies/types";
import { userMapActions } from "@/store/modules/user";
import { IUserModel } from "@/types";

import { updateColumnSelectionFromLocalStorage } from "@/helpers";
import { getSystemFilesURL } from "@/helpers";
export default Vue.extend({
  components: {
    ClaimStatusLookup,
    LossForm,
    Help,
    Charts,
    NoResultsFound,
    Exporter
  },
  data(): IClaims {
    return {
      successMessage: "",
      errorMessage: "",
      loading: false,
      messages: [
        {
          title: "Claims Inquiries",
          contacts: claimsInquiries
        }
      ],
      tableColumns: tableColumnsListClaims(),
      selectedClaims: [],
      customBulkMenuActions: customBulkMenuOptions,
      filters: [
        {
          field: "status",
          title: "Status",
          options: [
            { label: "Not Submitted", value: "Not Submitted" },
            { label: "Submitted", value: "Submitted" },
            { label: "Issued", value: "Issued" },
            { label: "Rejected", value: "Rejected" }
          ]
        },
        {
          field: "data.lossFormOtherInfo.Coverages.ClaimRepresentative",
          title: "Claim Representative",
          options: AtlasClaimRepresentativeOptions
        },
        {
          field: "data.lossFormOtherInfo.Coverages.FieldAssigned",
          title: "Field Adjuster",
          options: FieldAdjusterOptions
        },
        {
          field: "printed",
          title: "Printed",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" }
          ]
        },
        {
          field: "submittedOn",
          title: "Submitted On",
          isDate: true
        }
      ],
      showClaimsChart: "",
      queryOverride: { isLegacy: false },
      claimId: "",
      loadingText: "",
      queryForChart: {},
      claimType: "Claims",
      claimsNotFoundImage: ""
    };
  },
  async mounted() {
    try {
      this.getClaimsExaminerDetails();
      const url = await getSystemFilesURL("claims");
      if (url) {
        this.claimsNotFoundImage = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }

    if (
      this.$getCurrentUser.role !== "admin" &&
      this.$getCurrentUser.role !== "underwriting" &&
      !this.$isCurrentUserClaims
    ) {
      this.customBulkMenuActions = [];
    }
    this.getCompanies({});
  },
  computed: {
    ...claimsMapState(["makingApiRequest", "claims"]),
    ...claimsMapGetters(["getTotalRecords", "getClaimsLinks"]),
    ...companiesMapGetters(["companies"]),
    chart(): string {
      const { charts = [] } = this.$store.state.auth["initData"] || {};
      return charts.find((chart: any) => chart.id === this.showClaimsChart);
    },
    topButtonSecondaryItems(): {
      text?: string;
      key?: string;
      subItems?: { title: string; command: string }[];
    } {
      const isUserAllowed =
        this.$isCurrentUserAdmin ||
        this.$isCurrentUserUnderwriter ||
        this.$isCurrentUserClaims ||
        this.$isCurrentUserBilling ||
        this.$isCurrentUserDataEntry;
      const adminOptions = [
        {
          title: "Claims County Report",
          command: "claims-county"
        },
        {
          title: "Claims Status Report",
          command: "claims-status"
        },
        {
          title: "New Claims Report",
          command: "claims-new"
        }
      ];
      const userOptions = [
        {
          title: "Claim Status Lookup",
          command: "status-lookup"
        }
      ];

      const activeOptions =
        this.$isCurrentUserClaims || this.$isCurrentUserAdmin
          ? [...adminOptions, ...userOptions]
          : userOptions;

      const { deleted, isLegacy } = this.queryOverride;
      if (!deleted && isUserAllowed) {
        activeOptions.push({
          title: "Trash Bin",
          command: "claims-deleted"
        });
      }
      if (this.showClaimsChart || deleted || isLegacy) {
        activeOptions.push({
          title: "Claims",
          command: "claims"
        });
      }
      if (!isLegacy) {
        activeOptions.push({
          title: "Legacy Claims",
          command: "claims-legacy"
        });
      }

      return {
        text: "Actions",
        key: "actions",
        subItems: activeOptions
      };
    },
    tableRowActions(): any {
      const userRole =
        this.$getCurrentUser && this.$getCurrentUser.role
          ? this.$getCurrentUser.role
          : "";
      return getChangeRequestTableRowActions(userRole, this.queryOverride);
    },
    bulkMenuActionsData(): any {
      let bulkMenuActions = [{ title: "Print Selected", disabled: false }];
      if (
        this.$getCurrentUser &&
        (this.$getCurrentUser.role === "admin" ||
          this.$getCurrentUser.role === "underwriting" ||
          this.$isCurrentUserClaims)
      ) {
        const { deleted } = this.queryOverride;
        if (deleted) {
          bulkMenuActions.push({
            title: "Restore Selected",
            disabled: false
          });
        } else {
          bulkMenuActions.push({
            title: "Delete Selected",
            disabled: false
          });
        }
      }
      return bulkMenuActions;
    },
    claimsChartFilters(): any {
      return [
        {
          field: "companyNumbers",
          title: "Company",
          options: this.companyOptions
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        }
      ];
    },
    companyOptions(): { label: string; value: number }[] {
      return this.companies
        .filter(
          (company: any) => !excludedCompanies.includes(company.companyNumber)
        )
        .map((company: ICompany) => ({
          label: company.companyName,
          value: company.companyNumber
        }));
    },
    tableActions(): string[] {
      return this.$getCurrentUser?.role === "admin"
        ? ["Export csv", "All Activities"]
        : [];
    }
  },
  methods: {
    ...companiesMapActions(["getCompanies"]),
    ...userMapActions(["getContactUsDetails"]),
    ...claimsMapActions([
      "deleteClaims",
      "getClaims",
      "updateClaimsRequestsStatus",
      "updateClaims",
      "restoreClaimsRequests"
    ]),
    formatClaimsExaminerData(users: IUserModel[]) {
      const data: { title: string; contacts: Record<string, any>[] } = {
        title: "Claims Contacts",
        contacts: []
      };

      data.contacts = users.map((user: IUserModel) => {
        return {
          title: `${user.firstName} ${user.lastName} - ${user?.atlasAdmin?.title}`,
          text: user.phone?.extension ? `Ext ${user.phone?.extension}` : "",
          email: user.email,
          type: "email"
        };
      });
      this.messages.push(data);
    },
    async getClaimsExaminerDetails() {
      try {
        const response = await this.getContactUsDetails({
          atlasAdmin__exists: true,
          role__eq: "claims"
        });
        this.formatClaimsExaminerData(response);
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      }
    },
    getExportingListColumns() {
      return (
        Object.keys(tableColumnsListClaims())
          // .filter(e => !e.includes("ClassId"))
          .map(function(column) {
            const splitArray = column.split(".");
            const splitArrayLength = splitArray.length;
            const columnName = splitArrayLength
              ? splitArray[splitArrayLength - 1]
              : column;
            return { label: columnName, value: column };
          })
      );
    },
    claimsChartFetchHandler(query: any) {
      const { companyNumbers__in, createdOn__gte, createdOn__lte } = query;
      const queryObject: any = {};

      if (companyNumbers__in) {
        const companyNumberData =
          typeof companyNumbers__in === "number"
            ? [companyNumbers__in]
            : companyNumbers__in.split(",").map((num: string) => num);
        queryObject["compNum"] = {
          $in: companyNumberData
        };
      }
      if (createdOn__gte || createdOn__lte) {
        queryObject["createdOn"] = {};
        if (createdOn__gte) {
          queryObject["createdOn"]["$gte"] = new Date(createdOn__gte);
        }

        if (createdOn__lte) {
          queryObject["createdOn"]["$lte"] = new Date(createdOn__lte);
        }
      }
      this.queryForChart = queryObject;
    },
    async printCallback(_id: string, policyPrefix: string) {
      try {
        await downloadClaimsDocument({
          _ids: [_id],
          policyPrefix
        });
        this.$appNotifySuccess("Claims printed");
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      const policyPrefix =
        data &&
        data.data &&
        data.data.lossFormRequire &&
        data.data.lossFormRequire.policyNum
          ? data.data.lossFormRequire.policyNum.split(" ")[0]
          : undefined;
      switch (payload.name) {
        case "print": {
          await this.printCallback(data._id, policyPrefix);
          break;
        }
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          if (
            data.status === "Issued" ||
            (this.$getCurrentUser?.role !== "admin" &&
              data.status === "Submitted")
          ) {
            this.$router
              .push(`/lossformadmin/view/${data._id}`)
              .catch(() => {});
          } else {
            this.$router
              .push(`/lossformadmin/edit/${data._id}`)
              .catch(() => {});
          }
          break;
        case "restore":
          this.restoreClaim(data._id);
          break;
        case "view":
          this.$router.push(`/lossformadmin/view/${data._id}`).catch(() => {});
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async restoreClaim(id: string): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Restoring Claim...";
        await this.restoreClaimsRequests({ ids: [id] });
        this.$appNotifySuccess("Claims restored successfully");
        this.fetchPageData({});
      } catch (e) {
        this.$appNotifyError(e.message);
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async customBulkActionHandler(action: string) {
      switch (action) {
        case "Not Submitted":
        case "Submitted":
        case "Received":
        case "Issued": {
          try {
            this.updateStatus(action);
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        default:
          break;
      }
    },
    async updateStatus(status: any) {
      const ids = this.selectedClaims.map((doc: any) => doc._id);
      await this.updateClaimsRequestsStatus({
        ids: ids,
        status: status
      });
      this.$appNotifySuccess("Status changed");
      this.fetchPageData(this.$route.query);
    },
    tableActionHandler(action: string) {
      switch (action) {
        case "Export csv":
          this.$modal.show("exportClaimsModal");
          break;
        case "All Activities":
          this.$router.push(`/admin/claims-activities`).catch(() => {});
          break;

        default:
          break;
      }
    },
    async handleBulkClaimsPrinting() {
      try {
        this.loading = true;
        this.loadingText = "Printing selected claims...";
        await downloadClaimsDocument({
          _ids: this.selectedClaims.map((claim: any) => claim._id)
        });
        this.$appNotifySuccess("Claims printed");
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Print Selected": {
          await this.handleBulkClaimsPrinting();
          break;
        }
        case "Delete Selected": {
          try {
            this.loadingText = "Deleting Claims...";
            await deleteClaimsDocument({
              _ids: this.selectedClaims.map((claim: any) => claim._id)
            });
            this.$appNotifySuccess("Claims Successfully Deleted");
            this.fetchPageData(this.$route.query);
          } catch (e) {
            this.$appNotifyError(e.message);
            this.$bugSnagClient.notify(e);
          }
          break;
        }
        case "Restore Selected": {
          try {
            this.loadingText = "Restoring Claims...";
            await restoreClaimsDocument({
              ids: this.selectedClaims.map((claim: any) => claim._id)
            });
            this.$appNotifySuccess("Claims Successfully Restores");
            this.fetchPageData(this.$route.query);
          } catch (e) {
            this.$appNotifyError(e.message);
            this.$bugSnagClient.notify(e);
          }
          break;
        }
      }
    },
    async onToolbarItemClick(event: string) {
      switch (event) {
        case "file-a-claim":
          // this.$modal.show("lossFormModal");
          this.$router.push("/file-claim");
          break;
        case "status-lookup":
          this.$router.push("/claims-lookup");
          break;
        case "claims-county":
        case "claims-status":
        case "claims-new":
          this.showClaimsChart = event;
          this.claimType = "Claims Report";
          await this.$router.replace({ query: {} });
          this.queryForChart = {};
          break;
        case "claims":
          this.showClaimsChart = "";
          this.claimType = "Claims";
          this.queryOverride.deleted = false;
          this.queryOverride.isLegacy = false;
          this.fetchPageData({});
          break;
        case "claims-deleted":
          this.showClaimsChart = "";
          this.claimType = "Trash Bin";
          this.queryOverride = {
            ...this.queryOverride,
            deleted: true,
            status__in: []
          };
          this.fetchPageData({});
          break;
        case "claims-legacy":
          this.showClaimsChart = "";
          this.claimType = "Legacy claims";
          this.queryOverride.isLegacy = true;
          this.queryOverride.deleted = false;
          this.fetchPageData({});
          break;
      }
    },
    async deleteCallback(claimID: string) {
      try {
        this.claimId = claimID;
        this.$modal.show("claimsFormDeleteModal");
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async deleteOneClaim() {
      await this.deleteClaims(this.claimId);
      this.$modal.hide("claimsFormDeleteModal");
      this.$appNotifySuccess("Property Loss record deleted");
    },
    viewClaimsDetails(data: any) {
      if (
        data.status === "Issued" ||
        (data.status === "Submitted" && this.$getCurrentUser.role === "agent")
      ) {
        this.$router.push(`/lossformadmin/view/${data._id}`).catch(() => {});
      } else {
        this.$router.push(`/lossformadmin/edit/${data._id}`).catch(() => {});
      }
    },
    goToLossForm(policyData: any) {
      this.$router.push(`/lossformadmin/new/${policyData}`);
      this.$modal.hide("lossFormModal");
    },
    async fetchPageData(queryObject: any): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        this.$emit("scrollTop");
        const filteredQuery = omit(
          queryObject,
          "data.lossFormRequire.dtSubmitted_dateFilter",
          "data.lossFormOtherInfo.FormInfo.LossDate_dateFilter"
        );
        const claimsQuery = {
          query: {
            __limit: 50,
            __offset: 0,
            ...filteredQuery,
            ...this.queryOverride
          }
        };
        if (!queryObject.__sort) {
          claimsQuery.query.__sort = "-submittedOn";
        }

        if (!claimsQuery.query.deleted) {
          claimsQuery.query.deleted = false;
        }

        await this.getClaims(claimsQuery);
      } catch (error) {
        this.$appNotifyError("Error fetching Claims. Try again later");
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    }
  },
  watch: {
    "$route.query.__columns": {
      handler(newColumns) {
        if (newColumns) {
          updateColumnSelectionFromLocalStorage(newColumns, "claims");
        }
      },
      immediate: false
    }
  }
});
